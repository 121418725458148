footer {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #020022;
  
    .content {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 80vw;
      hr {
        margin: 0;
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
    footer {
      padding: 10px;
      .content {
        flex-direction: column;
        justify-content: center;
        align-items: start;
      }
    }
  }
  
  
  .socialMediaLinks {
    display: flex;
    justify-content: center;
  
    ul {
      list-style: none;
      display: inherit;
    }
  
    li a {
      text-decoration: none;
      margin: 0 10px;
  
      img {
        width: 50px;
      }
    }
  }
  
  .codeStat {
    background: wheat;
    border-radius: 50%;
  }
  
  .contact {
    display: flex;
    flex-direction: column;
  
    .list-title {
      font-size: large;
      font-weight: 600;
    }
  }
  
  .our_work {
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    .list-title {
      font-size: large;
      font-weight: 600;
    }
  
    ul {
      list-style: none;
      padding: 0;
    }
  }