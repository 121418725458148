@import './Variables.scss';

.primary-btn{
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: $btn-clr;
    font-weight: 600;
    &:hover{
        color: $secondary-color;
    }
}