@import "../../Variables.scss";

.contact {
    .banner {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-image: linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(255, 185, 34, 1) 100%);
        .title {
            width: 40vw;
            color: black;
            font-size: xx-large;
            font-weight: 700;
        }

        .image {
            width: 40vw;
        }
    }

    .content {
        background: linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(248,248,248,1) 40%);
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: auto;
        color: black;
        .details{
            display: flex;
            justify-content: space-between;
            width: 40vw;
            height: 80vh;
            .title{
                font-weight: bold;
            }
            .svg-inline--fa{
                font-size: xxx-large;
            }
            .first{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .address{
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: space-around;
                    height: 250px;
                    width: 250px;
                    background-color: white;
                    border-radius: 5%;
                    padding: 20px;
                    .svg-inline--fa{
                        color: lightblue;
                    }
                }
                .time{
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: space-around;
                    height: 250px;
                    width: 250px;
                    background-color: white;
                    border-radius: 5%;
                    padding: 20px;
                    .svg-inline--fa{
                        color: lightblue;
                    }
                }
            }
            .second{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .email{
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: space-around;
                    height: 250px;
                    width: 250px;
                    background-color: white;
                    border-radius: 5%;
                    padding: 20px;
                    .svg-inline--fa{
                        color: lightblue;
                    }
                }
                .phone{
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    justify-content: space-around;
                    height: 250px;
                    width: 250px;
                    background-color: white;
                    border-radius: 5%;
                    padding: 20px;
                    .svg-inline--fa{
                        color: lightblue;
                    }
                }
            }
        }
        .form {
            display: flex;
            flex-direction: column;
            color: black;

            .title {
                width: 40vw;
                color: black;
                font-size: xx-large;
                font-weight: 700;
            }
            .fields{
                form{
                    display: flex;
                    flex-direction: column;
                    label{
                        margin: 10px 0 5px 0;
                        font-weight: 600;
                    }
                    input, textarea{
                        padding: 10px;
                        border-radius: 5px;
                        border: 0.5px solid lightgray;
                    }
                    .primary-btn{
                        background-color: $common-btn-clr;
                        margin: 10px 0;
                    }
                }
            }
        }
    }
}