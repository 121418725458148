@import '../../Variables.scss';

.home {
    min-height: 80vh;
    background-color: white;
    background-image: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 0.5159313725490196) 80%, rgba(0, 212, 255, 1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    .left{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 30vw;
        .text{
            font-size: xx-large;
            font-weight: 600;
            b{
                color: $btn-clr;
            }
        }
        .btns{
            display: flex;
            justify-content: flex-start;
            button{
                margin: 0 10px 0 0;
            }
        }
    }
    .right{
        display: flex;
        justify-content: center;
        width: 60vw;

        img{
            width: auto;
        }
    }
}

@media only screen and (max-width: 600px) {
    .home{
        flex-direction: column;
        justify-content: space-around;
        min-height: max-content;
        padding: 20px;
        .left{
            justify-content: start;
            width: 90vw;
            .btns{
                flex-direction: column;
                button{
                    width: fit-content;
                    margin: 10px 0;
                }
            }
        }
        .right{
            img{
               display: none;
            }
        }
    }
}