@import '../../Variables.scss';


header{
    background-color: $primary-color;
    color: $secondary-color;
    position: fixed;
    width: 100%;
}
.header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 80px;
}
.leftSection{
    img{
        width: 250px;
    }
    font-size: 1.5rem;
    font-weight: 600;
    &:hover{
        cursor: pointer;
    }  
}
.rightSection {
    ul{
        display: flex;
        list-style: none;
        justify-content: space-around;
        margin: 0;
        li a{
            margin: 0 10px;
            text-decoration: none;
            color: white;
            &:hover{
                font-size: 1.01rem;
                color: lavender;
            }
        }
    }
}

@media only screen and (max-width:645px) { 
	.rightSection{
        display: none;
    }
    .leftSection{
        img{
            width: 30vw;
        }
    }
}