
@import '../../Variables.scss';
.pkg-container{
    margin: 10px 0;
    background-color: #0475A7;
    width: 30vw;
    height: 80%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    color: white;
    transition: 0.3s;
    &:hover{
        box-shadow: 9px 10px 26px 3px rgba(0,0,0,0.75);
    }

    .pkg-header{
        .title{
            font-size: x-large;
            font-weight: 700;
            color: white;
        }
    }
    .pkg-body{
        margin:10px 0;
        overflow: auto;
        font-size: large;
        ul li{
            list-style: none;
        }
        ul li:before {    
            content: '\f058';
            font-family: 'Font Awesome 5 free';
            margin:0 5px 0 -20px;
            color: $btn-clr;
        }
    }
    .pkg-footer{
        width: 100%;
        display: flex;
        flex-direction: column;
        .price{
            display: flex;
            span{
                b{
                    font-size: xx-large;
                    font-weight: bolder;
                }
            }
        }
    }
    ::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: #0475A7; 
        border-radius: 10px;
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius:10px;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
}