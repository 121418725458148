
// Color
$primary-color:#008cba;
$secondary-color:#ffff;
$footer-background:#2D3748;
$common-btn-clr: #0477AA;
$btn-clr: #FFB919;

//Font
$primary-font: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
$stylish-font: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
//Background colors
$secondary-backgroung-color:#e8f0f2;