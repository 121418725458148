@import '../../Variables.scss';

.pkgs{
    background-color: white;
    background-image: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 0.5159313725490196) 80%, rgba(0, 212, 255, 1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    .container{
        height: 90vh;
        background-color: white;
        display: flex;
        flex-direction: column;
        border-radius: 25px;
        .header{
            height: auto;
            .title{
                font-weight: 700;
                font-size: xx-large;
                height: auto;
                b{
                    color: $btn-clr;
                }
            }
        }
        .pkgs-container{
            height: 500px;
            display: flex;
            align-items: center;
        justify-content: space-around;
            overflow: auto;
        }
    }
}

@media only screen and (max-width:645px) { 
	.pkgs-container{
        flex-direction: column;
        height: 500px;
    }
    .pkg-container{
        width: 80vw !important;
    }
    .header{
        margin: 10px 0px;
    }
    .pkgs .container{
        border-radius: 0px;
    }
}